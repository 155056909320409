<template>
  <div class="page-index">
    <div class="hd">
      <div class="info">
        <div class="info-hd">付款给</div>
        <div class="info-bd">台山市人民医院</div>
      </div>
      <div class="info-icon">
        <img class="info-icon-img" src="../../assets/info-icon.png" alt="" />
      </div>
      <div class="bg"></div>
    </div>
    <div class="bd">
      <div class="box">
        <div class="box-hd">
          <div class="box-hd-label">费用总额</div>
          <div class="box-hd-value">{{ feeSumamt }}元</div>
        </div>
        <div class="box-bd">
          <div class="box-item">
            <div class="box-bd-label">医保基金支付</div>
            <div class="box-bd-value">{{ fundPay }}元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">个人帐户支付</div>
            <div class="box-bd-value">{{ psnAcctPay }}元</div>
          </div>
          <div class="box-item">
            <div class="box-bd-label">其他抵扣金额</div>
            <div class="box-bd-value">{{ othFeeAmt }}元</div>
          </div>
        </div>
        <div class="box-ft">
          <div class="box-ft-label">现金支付</div>
          <div class="box-ft-value">{{ ownPayAmt }}元</div>
        </div>
        <div class="box-append" @click="handleActionsheet">
          查看明细
        </div>
      </div>
      <div class="fund-pay">
        <div class="tiutle">个人账户支付</div>
        <div class="tab">
          <div
            v-for="(item, index) in fundType"
            :class="currentIndex == index ? 'active' : ''"
            :key="index"
            @click="changeTab(index)"
          >
            <span>{{ item.label }}</span>
          </div>
        </div>
      </div>
      <div class="bd-append">
        <i class="bd-append-icon"></i>
        <div class="bd-append-text">医保移动支付</div>
      </div>
    </div>
    <div class="ft">
      <div class="pay">
        <div class="pay-label">您还需支付：</div>
        <div class="pay-value">¥{{ ownPayAmt }}</div>
      </div>
      <div class="btn" @click="medicalOutpatientPay">去支付</div>
    </div>

    <div class="actionsheet" v-show="actionsheetVisible">
      <div class="actionsheet-mask" @click="handleActionsheet"></div>
      <div class="actionsheet-panel">
        <div class="actionsheet-hd">
          <div class="actionsheet-hd-tt">处方明细</div>
          <i class="actionsheet-icon-close" @click="handleActionsheet"></i>
        </div>
        <div class="actionsheet-bd">
          <div class="actionsheet-box-hd">费用信息</div>
          <div class="actionsheet-box">
            <div class="actionsheet-box-bd">
              <div
                class="actionsheet-box-item"
                v-for="(item, index) in orderDetail.item"
                v-bind:key="index"
              >
                <div class="actionsheet-box-item-label-wrap">
                  <div class="actionsheet-box-item-label">
                    {{ item.itemPrice }}元
                  </div>
                  <div class="actionsheet-box-item-sublabel">
                    {{ item.itemName }}
                  </div>
                </div>
                <div class="actionsheet-box-item-value">
                  x{{ item.itemNumber }}/{{ item.itemUnit }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
export default {
  name: "outpatient-medical",
  data() {
    return {
      prescriptionList: [],
      actionsheetVisible: false,
      orderNum: "",
      patCardNo: "",
      patCardType: "",
      rType: "8",
      orderDetail: {},
      medicalInsuranceType: "2",
      authCode1: "",
      payAuthNo: "",
      return2206: [],
      feeSumamt: "",
      idCard: "",
      authCode: "",
      retCode: "",
      fundPay: "",
      psnAcctPay: "",
      othFeeAmt: "",
      ownPayAmt: "",
      disabled: false,
      url: "",
      fundType: [
        {
          label: "使用"
        },
        {
          label: "不使用"
        }
      ],
      currentIndex: 0
    };
  },
  created() {
    let str = window.location.href;

    this.authCode = this.getQueryStringValue(str, "authCode");
    this.orderNum = window.localStorage.getItem("orderNum");
    this.patCardNo = window.localStorage.getItem("patCardNo");
    this.idCard = window.localStorage.getItem("idCard");
    this.patCardType = "1";

    if (this.authCode !== null) {
      this.getpayauthno();
      this.getOrderDetail();
      console.log(this.authCode);
      this.disabled == false;
    } else {
      return (window.location.href =
        "http://tssywx.tssrmyy.com/wsyb/#/outpatient/detail");
      //  return  window.location.href = "http://tssywx.tssrmyy.com/outpatient/detail"
      console.log(this.authCode);
    }
  },
  methods: {
    getQueryStringValue(url, key) {
      const match = url.match(new RegExp("[?&]" + key + "=([^&]+)"));
      return match ? match[1] : null;
    },
    changeTab(index) {
      this.currentIndex = index;
      if (index == "1") {
        this.medicalInsuranceType = "3";
      }
      this.getOrderDetail();
    },
    handleActionsheet() {
      this.actionsheetVisible = !this.actionsheetVisible;
    },
    //获取门诊待缴费明细
    async getOrderDetail() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const data = {
        openId: window.localStorage.getItem("openId"),
        patCardNo: this.patCardNo,
        idCard: this.idCard,
        patCardType: "1",
        orderNum: this.orderNum,
        visitType: "1",
        payAuthNo: this.payAuthNo,
        acctuseflag: this.currentIndex
        // openId: 'oss_K5VU43mgh0ukXw_7wLQX5HKc',
        // patCardNo: '3860125',
        // patCardType: '1',
        // orderNum: '2023051101090',
        // visitType: "1",
        // payAuthNo: 'AUTH440100202305111102120007993'
      };
      const { data: res } = await jsonPost(
        "/wxapp/outpatient/getClinicFeeDetail",
        data
      );

      if (res.code === 0) {
        if (res.data.OutStr) {
          this.$toast.clear();
          this.$dialog.alert({
            message: res.data.OutStr
          });
        }
        this.return2206 = JSON.parse(res.data.return2206);
        this.feeSumamt = this.return2206[1].feeSumamt;
        this.fundPay = this.return2206[1].fundPay;
        this.psnAcctPay = this.return2206[1].psnAcctPay;
        this.othFeeAmt = this.return2206[1].othFeeAmt;
        this.ownPayAmt = this.return2206[1].ownPayAmt;

        this.orderDetail = res.data;
        this.$toast.clear();
      } else {
        this.$toast.clear();
        this.$dialog.confirm({
          message: res.msg
        });
      }
    },
    //门诊医保下单
    async medicalOutpatientPay() {
      if (this.disabled == false) {
        this.disabled = true;
        this.$toast.loading({
          duration: 0,
          message: "加载中...",
          forbidClick: true
        });
        const data = {
          cost: parseFloat(this.orderDetail.totalAmout),
          openId: window.localStorage.getItem("openId"),
          orderNum: this.orderNum,
          medOrgOrd: this.orderDetail.medOrgOrd,
          patCardNo: this.patCardNo,
          patCardType: this.patCardType,
          idCard: this.idCard,
          recordType: "9",
          patName: this.orderDetail.patName,
          returnUrl: "http://tssywx.tssrmyy.com/wsyb/#/order/outpatient",
          //returnUrl: "http://tssywx.tssrmyy.com/order/outpatient",
          doctorName: this.orderDetail.doctorName,
          deptName: this.orderDetail.deptName,
          budgeting: this.orderDetail.budgeting,
          medicalInsurance: this.orderDetail.medicalInsurance,
          return2206: {
            payAuthNo: this.payAuthNo,
            payOrdId: this.return2206[0].payOrdId,
            setlLatlnt: "0,0"
          },
          medicalInsuranceType: this.medicalInsuranceType,
          medicalInsuranceHospitalType: "1"
        };
        const { data: res } = await jsonPost(
          "wxapp/outpatient/medicalInsuranceOutpatientPayUnifiedOrder",
          data
        );
        console.log("res", res);

        if (res.code === 0) {
          this.$toast.clear();
          window.localStorage.setItem("outTradeNo", res.data.outTradeNo);
          this.medicalPay(res.data.payAppid, res.data.payUrl);
        } else {
          this.$toast.clear();
          this.$dialog.alert({
            message: res.msg
          });
          setTimeout(() => {
            this.getOrderDetail();
          }, 2000);
        }
      } else {
        this.$dialog.alert({
          message: "请勿重复下单"
        });
      }
    },
    // 获取医保授权码
    async getpayauthno() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const data = {
        openId: window.localStorage.getItem("openId"),
        qrcode: this.authCode
      };
      const { data: res } = await jsonPost(
        "wxapp/medicarebills/getpayauthno",
        data
      );

      if (res.code === 0) {
        this.$toast.clear();
        this.payAuthNo = res.pay_auth_no;
        this.getOrderDetail();
      } else {
        this.$toast.clear();
        this.$toast({
          title: res.message,
          icon: "none",
          duration: 2500 //持续的时间
        });
      }
    },
    medicalPay(appId, path) {
      window.location.href = `${path}`;
      // wx.navigateToMiniProgram({
      //   appId, // APPID
      //   path, // path 路径
      //   success (res) {
      //     console.log("success", res);
      //   },
      //   fail (error) {
      //     console.log('fail:', error)
      //   },
      //   complete (res) {
      //     console.log('complete:', res)
      //   }
      // })
    }
  }
};
</script>

<style lang="less" scoped>
.page-index {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.hd {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  color: #fff;
  padding: 52px 40px 40px 40px;
  box-sizing: border-box;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  height: 320px;
  background-color: #3b71e8;
  border-radius: 0 0 50px 50px;
  z-index: 0;
}

.info {
  position: relative;
  z-index: 1;
  color: #fff;
}

.info-hd {
  font-size: 28px;
  line-height: 32px;
  color: rgba(255, 255, 255, 0.6);
}

.info-bd {
  font-size: 40px;
  line-height: 50px;
  margin-top: 3px;
  font-weight: bold;
}

.info-icon {
  position: relative;
  z-index: 1;
  width: 80px;
  height: 80px;
}

.info-icon-img {
  display: block;
  width: 100%;
  height: 100%;
}

.bd {
  flex: 1;
  position: relative;
  z-index: 1;
}

.box {
  border-radius: 20px;
  background-color: #fff;
  margin: 0 30px;
  padding: 0 30px;
  padding-bottom: 27px;
  box-shadow: 0 8px 16px #e6e6e6;
}

.box-hd {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 40px 0 42px;
  color: #606266;
}

.box-hd:before {
  content: "";
  position: absolute;
  left: -8px;
  bottom: 0;
  right: -8px;
  border-bottom: 1px dashed #d8d8d8;
  transform: scaleY(0.5);
}

.box-hd-label {
  font-weight: bold;
  font-size: 40px;
}

.box-hd-value {
  font-weight: bold;
  font-size: 40px;
}

.box-bd {
  padding-top: 24px;
}

.box-item {
  display: flex;
  justify-content: space-between;
  color: #909399;
  font-size: 30px;
  line-height: 30px;
  padding-bottom: 20px;
}

.box-item:last-child {
  padding-bottom: 0;
}

.box-ft {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  padding-bottom: 23px;
  font-size: 30px;
  color: #3b71e8;
}

.box-ft-label {
  font-weight: bold;
}

.box-ft-value {
  font-weight: bold;
}

.box-append {
  position: relative;
  font-size: 14px;
  line-height: 20px;
  color: #606266;
  text-align: center;
  padding: 20px 0 18px;
  font-size: 30px;
}

.box-append:before {
  content: "";
  position: absolute;
  left: -8px;
  top: 0;
  right: -8px;
  border-top: 1px dashed #d8d8d8;
  transform: scaleY(0.5);
}

.bd-append {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 52px;
  margin-bottom: 32px;
}

.bd-append-icon {
  display: block;
  width: 70px;
  height: 22px;
  background: url("../../assets/medical-logo.png") center center no-repeat;
  background-size: 100% 100%;
  margin-right: 16px;
}

.bd-append-text {
  font-size: 30px;
  line-height: 30px;
  color: #909399;
  font-weight: bold;
}

.ft {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 24px;
  padding-left: 26px;
  padding-right: 26px;
  padding-bottom: calc(24px + constant(safe-area-inset-bottom));
  padding-bottom: calc(24px + env(safe-area-inset-bottom));
}

.pay {
  display: flex;
}

.pay-label {
  font-size: 26px;
  line-height: 34px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 5px;
}

.pay-value {
  font-size: 26px;
  line-height: 34px;
  color: #3b71e8;
  font-weight: bold;
}

.btn {
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  color: #fff;
  background-color: #0d7cff;
  padding: 22px 34px;
  width: 200px;
  text-align: center;
  border-radius: 34px;
}

.ft:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: scaleY(0.5);
}

.fund-pay {
  padding: 0 40px;
  // display: flex;
  justify-content: space-between;

  .tiutle {
    margin-top: 40px;
    font-size: 29px;
  }

  .tab {
    display: flex;
    text-align: center;
    line-height: 50px;
    margin-top: 10px;
    // width: 50%;

    div {
      flex: 1;
      background-color: #f8f9fa;
      text-align: center;
      margin-left: 20px;
      border: 6px solid #cccccc;
      border-radius: 14px;
      font-size: 30px;
      height: 100px;
      // line-height: 100px;
      color: #66686c;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .active {
      color: #3b71e8;
      background-color: #f6f9ff;
      border: 3px solid #3b71e8;
    }
  }
}

.actionsheet-mask {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;
}

.actionsheet-panel {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 1001;
  border-radius: 20px 20px 0 0;
  max-height: 93vh;
  overflow-y: auto;
}

.actionsheet-hd {
  display: flex;
  justify-content: space-between;
  padding: 34px 24px 24px;
}

.actionsheet-hd-tt {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 3px;
  font-weight: bold;
  color: #303133;
}

.actionsheet-icon-close {
  display: block;
  width: 40px;
  height: 38px;
  background: url("../../assets/icon-close.png") center center no-repeat;
  background-size: 100% 100%;
}

.actionsheet-box {
  border: 2px solid #f8f8f8;
  border-radius: 10px;
  margin: 20px;
}

.actionsheet-box-hd {
  position: relative;
  font-size: 26px;
  line-height: 26px;
  font-weight: bold;
  color: #303133;
  margin: 30px 0 24px;
  padding: 0 32px;
}

.actionsheet-box-hd:before {
  content: "";
  position: absolute;
  font-size: 26px;
  left: 8px;
  top: 50%;
  width: 8px;
  height: 27px;
  transform: translateY(-50%);
  background-color: #3b71eb;
}

.actionsheet-box-bd {
  padding: 30px;
  border-top: 2px solid #f8f8f8;
}

.actionsheet-box-item {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  line-height: 32px;
}

.actionsheet-box-item + .actionsheet-box-item {
  margin-top: 22px;
}

.actionsheet-box-item-label {
  color: #606266;
}

.actionsheet-box-item-sublabel {
  color: #909399;
}

.actionsheet-box-item-value {
  color: #606266;
}

.actionsheet-box-item-value.em {
  color: #3b71eb;
}
</style>
